import store from "@/store";
import { confirmPrompt } from "@/utilities";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { Auth } from "aws-amplify";
import Ably from "ably";

// Pages: Account
import Home from "@/views/Home.vue";
import CompleteNewPassword from "@/views/CompleteNewPassword.vue";
import ConfirmEmail from "@/views/ConfirmEmail.vue";
import ResetPassword from "@/views/ResetPassword.vue";

// Pages: Reports
import NewPage from "@/views/NewPage.vue";
import Reports from "@/pages/Reports/index.vue";
import Report from "@/pages/Report/index.vue";
import AlarmsTab from "@/pages/Report/tabs/Alarms.vue";
import InvoiceTab from "@/pages/Report/tabs/Invoice.vue";
import CommentsTab from "@/pages/Report/tabs/Comments.vue";
import DepositaryTab from "@/pages/Report/tabs/Depositary.vue";
import PropertyDetailsTab from "@/pages/Report/tabs/PropertyDetails.vue";
import SignaturesTab from "@/pages/Report/tabs/Signatures.vue";
import VisitChecks from "@/pages/Report/tabs/VisitChecks.vue";
import KeysTab from "@/pages/Report/tabs/Keys.vue";
import KeysreturnTab from "@/pages/Report/tabs/KeysReturn.vue";
import ManualsTab from "@/pages/Report/tabs/Manuals.vue";
import MetersTab from "@/pages/Report/tabs/Meters.vue";
import RoomsTab from "@/pages/Report/tabs/Rooms.vue";

import Photos from "@/pages/Photos/index.vue";

import app from "../main";

// Pages: Dataentry reports
import DeReports from "@/pages/Dataentry/index.vue";
import DeAcknowledge from "@/pages/Dataentry/Acknowledge.vue";
import DeDownload from "@/pages/Dataentry/Download.vue";

// Download Photos
import DownloadPhotos from "@/components/DownloadPhotos.vue";

import EmailTemplates from "@/views/EmailTemplates.vue";
import EmailTemplate from "@/pages/EmailTemplate/index.vue";
import EmailTemplateDetails from "@/pages/EmailTemplate/tabs/EmailTemplateDetails.vue";
import EmailTemplateClients from "@/pages/EmailTemplate/tabs/Clients.vue";

import SMSTemplates from "@/views/SMSTemplates.vue";
import SMSTemplate from "@/pages/SMSTemplate/index.vue";
import SMSTemplateDetails from "@/pages/SMSTemplate/tabs/SMSTemplateDetails.vue";
import SMSTemplateClients from "@/pages/SMSTemplate/tabs/Clients.vue";

// Dictionary
import Dictionary from "@/views/Dictionary.vue";
import DictionaryConditions from "@/components/dictionary/DictionaryConditions.vue";
import DictionaryItems from "@/components/dictionary/DictionaryItems.vue";
import DictionaryTemplates from "@/components/dictionary/DictionaryTemplates.vue";
import DictionaryTemplatesPopulated from "@/components/dictionary/DictionaryTemplatesPopulated.vue";
import DictionaryReporttypes from "@/components/dictionary/DictionaryReporttypes.vue";
import DictionarySystemsetting from "@/components/dictionary/SystemSetting.vue";
import DictionaryFixflo from "@/components/dictionary/FixfloAdapter.vue";

// Conversion Matrix
import ConversionMatrix from "@/views/ConversionMatrix.vue";

// CustomerDictionary
import CustomerDictionary from "@/views/CustomerDictionary.vue";
import CustomerDictionaryConditions from "@/components/customerdictionary/DictionaryConditions.vue";
import CustomerDictionaryItems from "@/components/customerdictionary/DictionaryItems.vue";
import CustomerDictionaryTemplates from "@/components/customerdictionary/DictionaryTemplates.vue";
import CustomerDictionaryTemplatesPopulated from "@/components/customerdictionary/DictionaryTemplatesPopulated.vue";
import CustomerDictionaryMaintenanceflags from "@/components/customerdictionary/Maintenanceflags.vue";
import CustomerDictionaryCompliance from "@/components/customerdictionary/Compliance.vue";
import CustomerDictionaryTNC from "@/components/customerdictionary/TNC.vue";
import CustomerDictionaryLogo from "@/components/customerdictionary/Logo.vue";
import CustomerDictionaryAddress from "@/components/customerdictionary/Address.vue";
import CustomerDictionaryRooms from "@/components/customerdictionary/DictionaryRooms.vue";

import SysAdmin from "@/views/SysAdmin.vue";

import CognitoUserList from "@/views/CognitoUserList.vue";
import CognitoGroupList from "@/views/CognitoGroupList.vue";
import CognitoUserScreen from "@/views/CognitoUserScreen.vue";

// Diary
import Diary from "@/pages/Diary/index.vue";
import Scheduler from "@/pages/Diary/Scheduler.vue";
import PiScheduler from "@/pages/Diary/PiScheduler.vue";
import ManagementFilters from "@/pages/Diary/ManagementFilters.vue";

import BookingConfirmationResponse from "@/pages/Diary/BookingConfirmationResponse.vue";
import SMSConfirmationResponse from "@/pages/Diary/SMSConfirmationResponse.vue";

// Feedback
import ReportFeedback from "@/pages/Feedback/ReportFeedback.vue";
import FeedbackSuccess from "@/pages/Feedback/FeedbackSuccess.vue";
import FeedbackLocked from "@/pages/Feedback/FeedbackLocked.vue";
import ReportNotFound from "@/pages/Feedback/ReportNotFound.vue";

import DownloadXlsx from "@/views/DownloadXlsx.vue";

// Inspectors
import Inspectors from "@/views/Inspectors.vue";
import Inspector from "@/pages/Inspector/index.vue";
import InspectorRateCardTab from "@/pages/Inspector/tabs/RateCard.vue";
import InspectorAddressTab from "@/pages/Inspector/tabs/Address.vue";
import InspectorPreferredLocationsTab from "@/pages/Inspector/tabs/PreferredLocations.vue";
import InspectorExcludedClientsTab from "@/pages/Inspector/tabs/ExcludedClients.vue";
import InspectorPreferredClientsTab from "@/pages/Inspector/tabs/PreferredClients.vue";
import InspectorSchedulingTab from "@/pages/Inspector/tabs/Scheduling.vue";
import AreaHeatChart from "@/views/AreaHeatChart.vue";

// Customers
import Customers from "@/views/Customers.vue";
import Customer from "@/pages/Customer/index.vue";

// Customer tabs
import CustomerAddressTab from "@/pages/Customer/tabs/Address.vue";
import CustomerAccountTab from "@/pages/Customer/tabs/Account.vue";
import CustomerEmailTemplatesTab from "@/pages/Customer/tabs/EmailTemplates.vue";
import CustomerPoliciesTab from "@/pages/Customer/tabs/Policies.vue";
import CustomerLogisticsRulesTab from "@/pages/Customer/tabs/LogisticsRules.vue";
import CustomerPriceListTab from "@/pages/Customer/tabs/PriceList.vue";
import CustomerReportSettingsTab from "@/pages/Customer/tabs/ReportSettings.vue";
import CustomerEstablishmentsTab from "@/pages/Customer/tabs/Establishments.vue";
import CustomerDepositoryTab from "@/pages/Customer/tabs/Depository.vue";
import CustomerFixfloTab from "@/pages/Customer/tabs/Fixflo.vue";

// Depositary
import CustomerLinkbackResponse from "@/pages/Depositary/CustomerLinkbackResponse.vue";

// QC
import QCSummary from "@/pages/qc/Summary.vue";
import QCMemberSummary from "@/pages/qc/MemberSummary.vue";
import QCAllocate from "@/pages/qc/Allocate.vue";

// PropertyVisit Management
import PropertyVisitManagements from "@/views/PropertyVisitManagements.vue";

// Remote Support
import RemoteSupport from "@/pages/RemoteSupport/index.vue";

// User management
import UsermanagementUser from "@/pages/Usermanagement/index.vue";
import UserDetails from "@/pages/Usermanagement/tabs/Userdetails.vue";
import UserCustomerallocations from "@/pages/Usermanagement/tabs/Customerallocations.vue";

import { useToast } from "vue-toastification";
import { User } from "@/models";

const toasted = useToast();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/sign-in",
    name: "sign-in",
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "sign-in" */ '@/views/Home.vue'),
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/sign-out",
    name: "sign-out",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/complete-new-password",
    name: "complete-new-password",
    component: CompleteNewPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/confirm-email",
    name: "confirm-email",
    component: ConfirmEmail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/photos/:id/:src?/:type?/:itemref?",
    name: "photos",
    component: Photos,
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators", "Customers"],
    },
  },
  {
    path: "/reports/:id",
    name: "report",
    redirect: (to) => "/reports/" + to.params.id + "/invoice",
    component: Report,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators", "Customers"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "invoice",
        name: "report-invoice",
        component: InvoiceTab,
      },
      {
        path: "details",
        name: "report-details",
        component: PropertyDetailsTab,
      },
      {
        path: "rooms",
        name: "report-rooms",
        component: RoomsTab,
      },
      {
        path: "keys",
        name: "report-keys",
        component: KeysTab,
      },
      {
        path: "keysreturn",
        name: "report-keys-return",
        component: KeysreturnTab,
      },
      {
        path: "meters",
        name: "report-meters",
        component: MetersTab,
      },
      {
        path: "alarms",
        name: "report-alarms",
        component: AlarmsTab,
      },
      {
        path: "manuals",
        name: "report-manuals",
        component: ManualsTab,
      },
      {
        path: "comments",
        name: "report-comments",
        component: CommentsTab,
      },
      {
        path: "signatures",
        name: "report-signatures",
        component: SignaturesTab,
      },
      {
        path: "visitchecks",
        name: "visit-checks",
        component: VisitChecks,
      },
      {
        path: "depositary",
        name: "report-depositary",
        component: DepositaryTab,
      },
    ],
  },

  {
    path: "/customerlist/",
    redirect: "/customerlist/a",
  },
  {
    path: "/customerlist/:search([a-z]{1}|0-9)?",
    name: "customerlist",
    component: Customers,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },

  {
    path: "/customers/:id",
    name: "customer",
    component: Customer,
    redirect: (to) => "/customers/" + to.params.id + "/address",
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "address",
        name: "customer-address",
        component: CustomerAddressTab,
      },

      {
        path: "account",
        name: "customer-account",
        component: CustomerAccountTab,
      },

      {
        path: "pricelist",
        name: "customer-pricelist",
        component: CustomerPriceListTab,
      },

      {
        path: "establishments",
        name: "customer-establishments",
        component: CustomerEstablishmentsTab,
      },

      {
        path: "reportsettings",
        name: "customer-reportsettings",
        component: CustomerReportSettingsTab,
      },
      {
        path: "policies",
        name: "customer-policies",
        component: CustomerPoliciesTab,
      },
      {
        path: "logisticsrule",
        name: "customer-logisticsrule",
        component: CustomerLogisticsRulesTab,
      },
      {
        path: `depository`,
        name: "customer-depository",
        component: CustomerDepositoryTab,
      },
      {
        path: `fixflo`,
        name: "customer-fixflo",
        component: CustomerFixfloTab,
      },
    ],
  },

  {
    path: "/inspectorlist/",
    redirect: "/inspectorlist/a",
  },
  {
    path: "/inspectorlist/:search([a-z]{1}|0-9)?",
    name: "inspectorlist",
    component: Inspectors,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },
  {
    path: "/areaheatchart",
    name: "areaheatchart",
    component: AreaHeatChart,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },

  {
    path: "/inspectors/:id",
    name: "inspector",
    component: Inspector,
    redirect: (to) => "/inspectors/" + to.params.id + "/address",
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "address",
        name: "inspector-address",
        component: InspectorAddressTab,
      },

      {
        path: "ratecard",
        name: "inspector-ratecard",
        component: InspectorRateCardTab,
      },

      {
        path: "preferredlocations",
        name: "inspector-preferredlocations",
        component: InspectorPreferredLocationsTab,
      },

      {
        path: "excludedclients",
        name: "inspector-excludedclients",
        component: InspectorExcludedClientsTab,
      },

      {
        path: "preferredclients",
        name: "inspector-preferredclients",
        component: InspectorPreferredClientsTab,
      },

      {
        path: "scheduling",
        name: "inspector-scheduling",
        component: InspectorSchedulingTab,
      },
    ],
  },

  {
    path: "/dictionary",
    name: "dictionary",
    redirect: "/dictionary/items",
    component: Dictionary,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "conditions",
        name: "dictionary-conditions",
        component: DictionaryConditions,
      },
      {
        path: "items",
        name: "dictionary-items",
        component: DictionaryItems,
      },
      {
        path: "templates-populated",
        name: "dictionary-templates-populated",
        component: DictionaryTemplatesPopulated,
      },
      {
        path: "templates",
        name: "dictionary-templates",
        component: DictionaryTemplates,
      },
      {
        path: "reporttypes",
        name: "dictionary-reporttypes",
        component: DictionaryReporttypes,
      },
      {
        path: "systemsetting",
        name: "dictionary-systemsetting",
        component: DictionarySystemsetting,
      },
      {
        path: "fixflo",
        name: "dictionary-fixflo",
        component: DictionaryFixflo,
      },
    ],
  },
  {
    path: "/customerdictionary/:reporttype",
    name: "customerdictionary",
    redirect: (to) =>
      "/customerdictionary/" +
      to.params.reporttype +
      "/customerdictionaryitems",
    component: CustomerDictionary,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "customerdictionaryconditions",
        name: "customer-dictionary-conditions",
        component: CustomerDictionaryConditions,
      },
      {
        path: "customerdictionaryitems",
        name: "customer-dictionary-items",
        component: CustomerDictionaryItems,
      },
      {
        path: "customerdictionarytemplates-populated",
        name: "customer-dictionary-templates-populated",
        component: CustomerDictionaryTemplatesPopulated,
      },
      {
        path: "customerdictionarytemplates",
        name: "customer-dictionary-templates",
        component: CustomerDictionaryTemplates,
      },
      {
        path: "customerdictionaryrooms",
        name: "customer-dictionary-rooms",
        component: CustomerDictionaryRooms,
      },
      {
        path: "customerdictionarycompliance",
        name: "customer-dictionary-compliance",
        component: CustomerDictionaryCompliance,
      },
      {
        path: "customerdictionarymaintenanceflags",
        name: "customer-dictionary-maintenanceflags",
        component: CustomerDictionaryMaintenanceflags,
      },
      {
        path: "customerdictionarytnc",
        name: "customer-dictionary-tnc",
        component: CustomerDictionaryTNC,
      },
      {
        path: "customerdictionaryaddress",
        name: "customer-dictionary-address",
        component: CustomerDictionaryAddress,
      },
      {
        path: "customerdictionarylogo",
        name: "customer-dictionary-logo",
        component: CustomerDictionaryLogo,
      },
    ],
  },

  {
    path: "/conversionmatrix",
    name: "conversionmatrix",
    component: ConversionMatrix,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
      unsavedChangesWarning: true,
    },
  },

  {
    path: "/sysadmin",
    name: "sysadmin",
    redirect: "/sysadmin/userlist/a",
    component: SysAdmin,
    meta: {
      requiresAuth: true,
      requiresGroup: ["SysAdmin"],
      unsavedChangesWarning: false,
    },
    children: [
      {
        path: "userlist/:search([a-z]{1}|0-9)?",
        name: "userlist",
        component: CognitoUserList,
      },
      {
        path: "userlist/:id",
        name: "user",
        component: CognitoUserList,
      },
      {
        path: "grouplist",
        name: "grouplist",
        component: CognitoGroupList,
      },
    ],
  },
  {
    path: "/sysadmin/user/:sub",
    name: "sysadmin-user",
    component: CognitoUserScreen,
    meta: {
      requiresAuth: true,
      requiresGroup: ["SysAdmin"],
      unsavedChangesWarning: true,
    },
  },

  {
    path: "/download-photos/:ref",
    name: "download-photos",
    component: DownloadPhotos,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/feedback/:ref",
    name: "feedback",
    component: ReportFeedback,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/feedbacksuccess",
    name: "feedbacksuccess",
    component: FeedbackSuccess,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/feedbacklock",
    name: "feedbacklock",
    component: FeedbackLocked,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/feedbackreportnotfound",
    name: "reportnotfound",
    component: ReportNotFound,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/downloadmaintenancesummaryxslx/:ref",
    name: "downloadmaintenancesummaryxslx",
    component: DownloadXlsx,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/downloadmaintenancesummaryreportnotfound",
    name: "downloadmaintenancesummaryreportnotfound",
    component: ReportNotFound,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/dereports",
    name: "dereports",
    component: DeReports,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Dataentry"],
    },
  },
  {
    path: "/dereports/:id",
    name: "dereport",
    redirect: (to) => "/dereports/" + to.params.id + "/details",
    component: Report,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Dataentry"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "details",
        name: "dereport-details",
        component: PropertyDetailsTab,
      },
      {
        path: "rooms",
        name: "dereport-rooms",
        component: RoomsTab,
      },
      {
        path: "keys",
        name: "dereport-keys",
        component: KeysTab,
      },
      {
        path: "meters",
        name: "dereport-meters",
        component: MetersTab,
      },
      {
        path: "alarms",
        name: "dereport-alarms",
        component: AlarmsTab,
      },
      {
        path: "manuals",
        name: "dereport-manuals",
        component: ManualsTab,
      },
      {
        path: "comments",
        name: "dereport-comments",
        component: CommentsTab,
      },
      {
        path: "signatures",
        name: "dereport-signatures",
        component: SignaturesTab,
      },
      {
        path: "visitchecks",
        name: "devisit-checks",
        component: VisitChecks,
      },
    ],
  },

  {
    path: "/dataentry/download/:id",
    name: "dedownload",
    component: DeDownload,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataentry/acknowledge/:id",
    name: "deacknowledge",
    component: DeAcknowledge,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/diary",
    name: "newbooking",
    redirect: "/diary/new",
    meta: {
      requiresAuth: true,
      requiresGroup: ["Booking", "QualityControl", "BookingManagement"],
      unsavedChangesWarning: true,
    },
  },
  {
    path: "/diary/:id",
    name: "existingbooking",
    component: Diary,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Booking", "QualityControl", "BookingManagement"],
      unsavedChangesWarning: true,
    },
  },
  {
    path: "/scheduler",
    name: "schedulerwithoutdate",
    component: Scheduler,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Booking", "QualityControl", "BookingManagement"],
    },
  },
  {
    path: "/scheduler/:date",
    name: "schedulerwithdate",
    component: Scheduler,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Booking", "QualityControl", "BookingManagement"],
    },
  },
  {
    path: "/pischeduler/:date",
    name: "pischeduler",
    component: PiScheduler,
    meta: {
      requiresAuth: true,
      requiresGroup: ["DiaryUser"],
    },
  },
  {
    path: "/emailtemplates",
    name: "emailtemplates",
    component: EmailTemplates,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },
  {
    path: "/emailtemplate/:id",
    name: "emailtemplate",
    redirect: (to) =>
      "/emailtemplate/" + to.params.id + "/emailtemplatedetails",
    component: EmailTemplate,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
    children: [
      {
        path: "emailtemplatedetails",
        name: "email-template-details",
        component: EmailTemplateDetails,
      },
      {
        path: "emailtemplateclients",
        redirect: "emailtemplateclients/a",
      },
      {
        path: "emailtemplateclients/:search([a-z]{1}|0-9)?",
        name: "email-template-clients",
        component: EmailTemplateClients,
        meta: {
          requiresAuth: true,
          requiresGroup: ["Administrators"],
        },
      },
    ],
  },

  {
    path: "/smstemplates",
    name: "smstemplates",
    component: SMSTemplates,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },
  {
    path: "/smstemplate/:id",
    name: "smstemplate",
    redirect: (to) => "/smstemplate/" + to.params.id + "/smstemplatedetails",
    component: SMSTemplate,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
    children: [
      {
        path: "smstemplatedetails",
        name: "sms-template-details",
        component: SMSTemplateDetails,
      },
      {
        path: "smstemplateclients",
        redirect: "smstemplateclients/a",
      },
      {
        path: "smstemplateclients/:search([a-z]{1}|0-9)?",
        name: "sms-template-clients",
        component: SMSTemplateClients,
        meta: {
          requiresAuth: true,
          requiresGroup: ["Administrators"],
        },
      },
    ],
  },

  {
    path: "/booking/confirmation/:bookingid/:response",
    name: "bookingconfirmation",
    component: BookingConfirmationResponse,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/smsconfirmationresponse/:bookingid",
    name: "",
    component: SMSConfirmationResponse,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/diarymanagementfilters",
    name: "management-filters",
    component: ManagementFilters,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
      unsavedChangesWarning: true,
    },
  },
  {
    path: "/depositary/linkbackconfirm",
    name: "depositary-linkbackconfirm",
    component: CustomerLinkbackResponse,
    meta: {
      requiresAuth: false,
      requiresGroup: [],
      unsavedChangesWarning: false,
    },
  },

  {
    path: "/qc/summary",
    name: "qc-summary",
    component: QCSummary,
    meta: {
      requiresAuth: true,
      requiresGroup: ["QualityControl"],
      unsavedChangesWarning: true,
    },
  },
  {
    path: "/qc/allocate",
    name: "qc-allocate",
    component: QCAllocate,
    meta: {
      requiresAuth: true,
      requiresGroup: ["QualityControl"],
      unsavedChangesWarning: true,
    },
  },
  {
    path: "/qc/membersummary",
    name: "qc-membersummary",
    component: QCMemberSummary,
    meta: {
      requiresAuth: true,
      requiresGroup: ["QualityControl"],
      unsavedChangesWarning: true,
    },
  },
  {
    path: "/propertyvisitmanagements",
    name: "propertyvisitmanagements",
    component: PropertyVisitManagements,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },

  {
    path: "/remotesupport",
    name: "remotesupport",
    component: RemoteSupport,
    meta: {
      requiresAuth: true,
      requiresGroup: ["Administrators"],
    },
  },

  {
    path: "/usermanagement/:sub",
    name: "usermanagement",
    component: UsermanagementUser,
    redirect: (to) => "/usermanagement/" + to.params.sub + "/userdetails",
    meta: {
      requiresAuth: true,
      requiresGroup: ["SysAdmin"],
      unsavedChangesWarning: true,
    },
    children: [
      {
        path: "userdetails",
        name: "user-details",
        component: UserDetails,
      },

      {
        path: "customerallocations",
        name: "user-customer-allocations",
        component: UserCustomerallocations,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

/**
 * Global guard to check if we are signed in with Cognito
 */
router.beforeResolve(async (to, from) => {
  if (to.meta.requiresAuth) {
    try {
      const data = await Auth.currentAuthenticatedUser();

      if (data && data.signInUserSession) {
        store.commit("auth/setCognitoUser", data);
      }

      if (Array.isArray(to.meta.requiresGroup)) {
        const assignedGroups = store.getters["auth/groups"];

        const isAllowedAccess = assignedGroups.some((assignedGroup: string) =>
          (to.meta.requiresGroup as string[]).includes(assignedGroup)
        );

        if (false === isAllowedAccess) {
          throw Error("You are not unauthorised to access that resource.");
        }
      }

      // Get the Office
      const { attributes } = await Auth.currentUserInfo();
      const officeId = attributes["custom:office_id"];

      if (!officeId) {
        throw Error(
          "You have not been assigned to an office. Please contact an administrator to access the system."
        );
      }

      store.commit("offices/setCurrentOfficeId", officeId);

      return true;
    } catch (err: any) {
      console.error("router.beforeResolve", err);

      if (err.message) {
        toasted.error(err.message);
      }

      await Auth.signOut();

      store.commit("auth/setCognitoUser", null);

      return {
        path: "/sign-in",
        query: { redirect: to.fullPath },
      };
    }
  } else {
    return true;
  }
});

/**
 * Global guard to check we have loaded various collections
 */
router.beforeResolve(async (to, from) => {
  if (to.meta.requiresAuth) {
    try {
      await Auth.currentAuthenticatedUser().then((data: any) => {
        if (data && data.signInUserSession) {
          // GET /dictionary requires a Cognito session
          if (store.getters["dictionary/isEmpty"]) {
            store.dispatch("dictionary/getDictionary");
          }
          // GET /offices requires a Cognito session
          if (store.getters["offices/isEmpty"]) {
            store.dispatch("offices/getOffices");
          }

          // If booking user, get all inspectors
          if (
            (store.getters["auth/isBooking"] ||
              store.getters["auth/isQCUser"] ||
              store.getters["auth/isBookingManagement"]) &&
            !store.getters["auth/isInspector"]
          ) {
            if (store.getters["diary/isInspectorlistEmpty"]) {
              store.dispatch("diary/getInspectors");
            }
            if (store.getters["emailtemplates/isEmpty"]) {
              store.dispatch("emailtemplates/getEmailTemplates");
            }
            if (store.getters["smstemplates/isEmpty"]) {
              store.dispatch("smstemplates/getSMSTemplates");
            }
          }

          const currentuser = store.getters["usermanagement/current"];
          const useremail = store.getters["auth/email"];
          if (!currentuser || !currentuser.email != useremail) {
            const useremail = store.getters["auth/email"];
            store
              .dispatch("usermanagement/getUser", useremail)
              .then((u: User) => {
                if (u?.id) {
                  store.commit("usermanagement/setCurrentUser", u);
                }
              });
          }
          if (store.getters["usermanagement/usersNotLoaded"]) {
            store.dispatch("usermanagement/getUsers");
          }
        }
      });
    } catch (err: any) {
      store.commit("dictionary/setDictionary", null);
    }
  }

  return true;
});

/**
 * Global guard to check we have loaded various collections
 */
// router.beforeResolve(async (to, from) => {
//   if (to.meta.requiresAuth) {
//     try {
//       await Auth.currentAuthenticatedUser().then(async (data: any) => {
//         if (data && data.signInUserSession) {
//           if (store.getters["auth/isBooking"]) {
//             if (
//               store.getters["diary/isCustomerlistEmpty"] &&
//               to.name != "customers"
//             ) {
//               await store.dispatch("diary/getAllCustomers");
//             }
//           }
//         }
//       });
//     } catch (err: any) {
//       store.commit("dictionary/setDictionary", null);
//     }
//   }

//   return true;
// });
/**
 * Global guard to confirm leaving a route with unsaved changes
 */
router.beforeEach(async (to, from) => {
  // This allows us to set `meta.unsavedChangesWarning` on parent routes
  const topmostFromRoute = from;
  const topmostToRoute = to;

  if (topmostFromRoute === undefined) {
    console.log('"From" route is empty');
    return true;
  } else if (topmostToRoute === undefined) {
    console.log('"To" route is empty');
    return true;
  } else if (topmostFromRoute.name === topmostToRoute.name) {
    /*if (
      topmostFromRoute.name === "existingbooking" &&
      topmostToRoute.name === "existingbooking" &&
      from.params?.id != to.params?.id &&
      store.getters["diary/hasUnsavedChanges"]
    ) {
      try {
        await confirmPrompt(
          "You have unsaved changes. Do you want to continue without saving your changes?"
        );

        // confirmPrompt() throws an error on Cancel, so the following lines
        // will only execute if OK has been selected
        console.log("OK, leave");
        store.commit("customers/resetUnsavedChanges");
        store.commit("dictionary/resetUnsavedChanges");
        store.commit("customerdictionary/resetUnsavedChanges");
        store.commit("reports/resetUnsavedChanges");
        store.commit("diary/resetUnsavedChanges");
        return true;
      } catch (data) {
        console.log("No, stay");
        return false;
      }
    } else {
      console.log("Topmost route has not changed");
      return true;
    }*/
    return true;
  } else if (
    !topmostFromRoute.meta.unsavedChangesWarning ||
    isSiblingRoute(topmostFromRoute, topmostToRoute)
  ) {
    console.log(
      'No "Unsaved changes" warning for route you left or going to sibling route'
    );
    return true;
  } else if (
    store.getters["customers/hasUnsavedChanges"] ||
    store.getters["dictionary/hasUnsavedChanges"] ||
    store.getters["customerdictionary/hasUnsavedChanges"] ||
    store.getters["reports/hasUnsavedChanges"] ||
    store.getters["diary/hasUnsavedChanges"]
  ) {
    console.log("Unsaved changes found: display prompt");

    try {
      await confirmPrompt(
        "You have unsaved changes. Do you want to continue without saving your changes?"
      );

      // confirmPrompt() throws an error on Cancel, so the following lines
      // will only execute if OK has been selected
      console.log("OK, leave");
      store.commit("customers/resetUnsavedChanges");
      store.commit("dictionary/resetUnsavedChanges");
      store.commit("customerdictionary/resetUnsavedChanges");
      store.commit("reports/resetUnsavedChanges");

      // Unsubscribe from diary channel
      try {
        const realtime = app.config.globalProperties.$realtime;
        const channel = realtime.channels.get("diary");
        channel.detach();
      } catch (err) {
        toasted.error(
          "Error while unsubscribing from realitime events channel"
        );
      }
      return true;
    } catch (data) {
      console.log(data);
      console.log("No, stay");
      return false;
    }
  } else {
    console.log("NO unsaved changes found: continue without prompting");
    return true;
  }
});

const isSiblingRoute = (from: any, to: any) => {
  const name1 = from.name;
  const name1parts = name1.split("-");
  const name2 = to.name;
  const name2parts = name2.split("-");
  return (
    name1parts.length && name2parts.length && name1parts[0] === name2parts[0]
  );
};

router.afterEach(async (to, from) => {
  const topmostFromRoute = from;
  const topmostToRoute = to;

  if (
    topmostToRoute.path.includes("diary") &&
    !topmostToRoute.path.includes("managementfilters")
  ) {
    document.title = "Booking form";
  } else if (topmostToRoute.path.includes("scheduler")) {
    document.title = "Diary";
  } else if (topmostToRoute.path.includes("reports")) {
    document.title = "Reports";
  } else {
    document.title = "ACT Property CMS";
  }
});

export default router;
