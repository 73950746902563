<template>
  <div class="user">
    <div class="row">
      <div class="col-1" style="text-align: start;">
        <i class="fas fa-arrow-left fa-2x" @click="back()"></i>
      </div>
      <div class="col-10">
        <h1 v-if="user.name">{{user.name}}</h1>
        <h1 v-else-if="user.email">{{user.email}}</h1>
        <h1 v-else>New user</h1>
      </div>
    </div>

    <div class="user-header">
      <form>
        <fieldset>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Name</label>
            <div class="col-sm-4">
              <input
                v-model="user.name"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Customer allocation</label>
            <div class="col-sm-4">
              <multiselect-text
                v-model="customers"
                :options="reportTypes"
                :allow-empty="true"
                :taggable="false"
                :custom-label="formatReportType"
                multiple
                @select="onSelectReporttype"
                @change="editTemplate('type', $event)"
              >
              </multiselect-text>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Email</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                :value="user.email"
                disabled
              />
            </div>
          </div>

          <div class="form-group row">
              <label class="col-sm-3 col-form-label">Signature</label>
              <div class="col-sm-9 body-editor" :height="messageheight">
                <ckeditor :editor="signatureeditor" v-model="signatureeditordata" :config="signatureEditorConfig"></ckeditor>
              </div>
            </div>

          <div class="form-group row">
            <div class="col-sm-3"></div>
            <div class="col-sm-4">
              <button type="submit" class="btn btn-primary" @click.prevent="save">Save</button>
            </div>
          </div>

        </fieldset>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Editor from "ckeditor5/ckeditor";
import { User} from '@/models';
import router from '@/router';
import { computed, onMounted, ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

const height = ref(window.innerHeight);

const toasted = useToast();

const store = useStore();
const user = computed((): User => store.getters['usermanagement/selected']);
const getUser = async (email: string): Promise<User> => {
  return store.dispatch('usermanagement/getUser', email);
};
const setUser = async (user: User) => {
  return store.commit('usermanagement/setSelectedUser', user);
};
const saveUser = async (user: User): Promise<void> => {
  return store.dispatch('usermanagement/saveUser', user)
};
const getCognitouser = async (sub: any) => {
  return store.dispatch('sysadmin/getUser', sub)
}

const messageheight = computed(() => {
  return height.value - height.value * 0.30;
});

const signatureeditor = computed(() => {
  return Editor;
});

const signatureEditorConfig = computed(() => {
  return {
    toolbar: {
      items: ['heading', '|', 'bold', 'italic', 'link', '|', 'alignment:left', 'alignment:right', 'alignment:center',
        'alignment:justify', '|', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'outdent', 'indent',
        '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo',],
    },
    licenseKey:
      "UUI3NHlwcFFOOXZpdFlqSFlhSUFYbWpCRHY4MnpuQWR1Mk95K2ZZWkxKSlVGclUrSFAvWEpmYndNRzNKLU1qQXlOREF4TURZPQ==",
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
  };
});

const signatureeditordata = computed({
  get: () => user.value.signature,
  set: (data: string) => {
    user.value.signature = data;
  }
});

onMounted(async () => {
  const cognitouser = await getCognitouser(router.currentRoute.value.params.sub);
  let email = '';
  if(cognitouser) {
    email = cognitouser.findAttributeValue('email');
    if(email){
      getUser(email)
      .then(async (u: User) => {
        if(u?.id) {
          setUser(u);
        }
        else {
          addNewUser();
        }
      })
      .catch((err: any) => {
        if(err?.response?.status === 404) {
          addNewUser();
        }
      })
    }
  }
});

const addNewUser = async () => {
  let email = '';
  const cognitouser = await getCognitouser(router.currentRoute.value.params.sub);
  if(cognitouser) {
    email = cognitouser.findAttributeValue('email');
    const newuser: User = new User({email: email});
    const u: User = await store.dispatch('usermanagement/addUser', newuser);
    setUser(u);
  }
}

const save = () => {
  saveUser(user.value)
  .then(() => toasted.success("Saved"));
}

const back = () => {
  router.back();
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

        
        