import { Module } from "vuex";
import { RootState } from "../types";
import { UsermanagementState } from "./types";
import { User } from "@/models/user.model";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

/**
 * Default state
 */
export const state: UsermanagementState = {
  updates: [],
  current: new User(),
  selected: new User(),
  customers: [],
  users: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const usermanagement: Module<UsermanagementState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default usermanagement;
