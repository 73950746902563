/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { UsermanagementState } from "./types";
import { User, Customer } from "@/models";
import usermanagement from ".";

export const getters: GetterTree<UsermanagementState, RootState> = {
  /**
   * The current User
   *
   * @param {UsermanagementState} state
   * @returns user User
   */
  current: (state: UsermanagementState): User => state.current,

  /**
   * The selected User
   *
   * @param {UsermanagementState} state
   * @returns user User
   */
  selected: (state: UsermanagementState): User => state.selected,

  /**
   * The list of all users
   *
   * @param {UsermanagementState} state
   * @returns list User[]
   */
  users: (state: UsermanagementState): User[] => state.users,

  /**
   * The list of customers
   *
   * @param {UsermanagementState} state
   * @returns list Customer[]
   */
  customers: (state: UsermanagementState): Customer[] => state.customers,

  /**
   * The updates which have been made since creating/loading/last-saving the current user
   *
   * @param {UsermanagementState} state
   * @returns void
   */
  updates: (state: UsermanagementState): string[] => state.updates,

  /**
   * Checks if current user has been loaded
   *
   * @param {UsermanagementState} state
   * @returns boolean
   */
  userNotLoaded: (state: UsermanagementState): boolean =>
    !state.current || !state.current.id,

  /**
   * Checks if users list has been loaded
   *
   * @param {UsermanagementState} state
   * @returns boolean
   */
  usersNotLoaded: (state: UsermanagementState): boolean =>
    !state.users || !state.users.length,

  /**
   * The updates which have been made since creating/loading/last-saving the current user
   *
   * @param {UsermanagementState} state
   * @returns boolean
   */
  hasUnsavedChanges: (state: UsermanagementState): boolean =>
    state.updates.length > 0,
};
